import { Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { Ticket } from 'src/app/models/ticket.model';
import { TicketService } from 'src/app/services/ticket/ticket.service';
import { User } from 'src/app/models/user.model';
import { DataService } from '../../services/data/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { AuthService } from 'src/app/services/auth/auth.service';
@Component({
  selector: 'app-sitehistory',
  templateUrl: './sitehistory.component.html',
  styleUrls: ['./sitehistory.component.css']
})
export class SitehistoryComponent implements OnInit {
  isChecked = true;
  private storageCheck = 0;
  keyword: string;
  userId: string;
  History = ['Draft', 'Informed', 'More Info', 'In Progress', 'Accepted', 'Assigned', 'Resolved', 'Closed', 'Rejected', 'Pending'];
  ticket$: Observable<Ticket[]>;
  status = 'All';
  currentName: string;
  User: User;
  myOptions: IAngularMyDpOptions = {
    dateRange: true,
    dateFormat: 'dd/mm/yyyy'
  }
  dateRange: IMyDateModel = null;
  user: User;
  siteState: string;
  public filterTicketForm: FormGroup
  constructor(
    private ticketService: TicketService,
    public dataService: DataService,
    public fb: FormBuilder,
    public auth: AuthService,
    @Inject('SOURCES') public Sources: any[],
  ) { 
  }

  ngOnInit() {
    this.auth.user$.subscribe(user => {
      this.user = user
      this.siteState = this.user.site
      this.User = this.auth.authState;
      // this.getCheck();
      this.buildForm();
      this.isFilter();
    });
  }

  checkValue(isChecked: boolean) {
    if (isChecked === true) {
      this.setCheck(0)
    } else {
      this.setCheck(1)
    }
    this.search()
  }
  setCheck(data: number) {
    localStorage.setItem(String(this.storageCheck), String(data));
    this.getCheck()
  }
  getCheck() {
    const data = Number(localStorage.getItem(String(this.storageCheck)))
    this.isChecked = data === 0;
  }
  
  search() {
    if (this.dateRange == null) {
    if (this.keyword !== undefined && this.keyword !== null && this.keyword !== '') {
      if (this.isChecked === true) {
        this.getByKeywordUserIdInCreatorSite(this.siteState,this.userId,this.keyword)
      } else if (this.isChecked === false) {
        this.getByKeywordUserIdInSite(this.siteState,this.keyword)
      }
    }  else {
      this.isFilter()
    }
  } else {
    this.onDateChanged(this.dateRange)
  }
  }

  getByUserIdInCreatorSite(site: string,userId: string) {
    this.ticket$ = this.ticketService.getByUserIdInCreatorSite(site,userId)
      .snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Ticket;
          const id = a.payload.doc['id'];
          return { id, ...data };
        }))
      );

  }

  getByKeywordUserIdInCreatorSite(site: string,userId: string,keyword: string) {
    this.ticket$ = this.ticketService.getByKeywordUserIdInCreatorSite(site,userId,keyword)
      .snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Ticket;
          const id = a.payload.doc['id'];
          return { id, ...data };
        }))
      );
  }

  getByUserIdInSite(site: string) {
    this.ticket$ = this.ticketService.getByUserIdInSite(site)
      .snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Ticket;
          const id = a.payload.doc['id'];
          return { id, ...data };
        }))
      );
  }

  getByKeywordUserIdInSite(site: string,keyword:string) {
    this.ticket$ = this.ticketService.getByKeywordUserIdInSite(site,keyword)
      .snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Ticket;
          const id = a.payload.doc['id'];
          return { id, ...data };
        }))
      );
  }

  private isFilter() {
    //console.logog(this.isChecked );
    if (this.isChecked === true) {
      this.getCurrentUserByRoles()
    } else {
      this.getAllTicket(this.status) 
    }
  }

  private getCurrentUserByRoles() {
    //console.logog(this.status);
    this.currentName = this.User.fullName
    this.userId = this.User.uid
    this.status === 'All' ? this.getAllTicket(this.status) : this.getTicketsListByUserIdStatus(this.userId, this.status)
  }


  private getTicketsListByUserIdStatus(userId: string, status: string) {
    this.ticket$ = this.ticketService.getTicketsListByUserIdStatus(userId, status)
      .snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Ticket;
          const id = a.payload.doc['id'];
          return { id, ...data };
        }))
      );
  }

  private getAllTicket(status: string) {
    if (this.keyword) {
      this.search()
    } else {
      if (this.isChecked === true) {
        this.getByUserIdInCreatorSite(this.siteState,this.userId)
      } else {
        this.getByUserIdInSite(this.siteState)
      }
    }
  }
  getSourcesIcon(source: string) {
    for (let i = 0; this.Sources.length; i++) {
      if (this.Sources[i].name === source) {
        return this.Sources[i].icon
      }
    }
  }
  checkDueDate(minDueDate: { seconds: number; }) {
    if (minDueDate) {
      let isDueDate: boolean
      const endDate = new Date(minDueDate.seconds * 1000)
      const currentDate = new Date()
      isDueDate = endDate < currentDate;
      return isDueDate
    }
  }
  newPath() {
    this.dataService.changeRedirectSource('sitehistory')
  }

  onDateChanged(event: IMyDateModel) {
    //console.logog('onDateChanged');
    this.dateRange = event
    var startDate = event.dateRange.beginJsDate
    var endDate = event.dateRange.endJsDate
    endDate.setUTCHours(40);
    if (startDate != null && endDate != null) {
      if (this.isChecked === true && ( this.keyword == undefined || this.keyword == null || this.keyword == '')) {
        this.getByDateRangeKeywordUserId(startDate, endDate, this.userId, this.siteState)
        //console.logog('getByDateRangeKeywordUserId')
      } 
      else if (this.isChecked === false && (this.keyword == undefined || this.keyword == null || this.keyword == '')) {
        this.getByDateRangeKeywordSite(startDate, endDate, this.siteState)
        //console.logog('getByDateRangeKeywordSite')
      }
      else if (this.isChecked === true && (this.keyword !== undefined || this.keyword !== null || this.keyword !== '')) {
        this.getByDateRangeKeywordUserIdHasSearch(startDate, endDate, this.userId, this.siteState,this.keyword)
        //console.logog('getByDateRangeKeywordUserIdHasSearch')
      } 
      else if (this.isChecked === false && (this.keyword !== undefined || this.keyword !== null || this.keyword !== '')) {
        this.getByDateRangeKeywordSiteHasSearch(startDate, endDate, this.siteState,this.keyword)
        //console.logog('getByDateRangeKeywordSiteHasSearch')
      }
    } else {
      this.search();
    }
  }

   getByDateRangeKeywordUserId(startDate: Date, endDate: Date, userId: string, site: string) {
    this.ticket$ = this.ticketService.getByDateRangeKeywordUserId(startDate, endDate, userId, site)
      .snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Ticket;
          const id = a.payload.doc['id'];
          return { id, ...data };
        }))
      );
  }

  getByDateRangeKeywordUserIdHasSearch(startDate: Date, endDate: Date, userId: string, site: string,keyword: string) {
    this.ticket$ = this.ticketService.getByDateRangeKeywordUserIdHasSearch(startDate, endDate, userId, site,keyword)
      .snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Ticket;
          const id = a.payload.doc['id'];
          return { id, ...data };
        }))
      );
  }

  getByDateRangeKeywordSiteHasSearch(startDate: Date, endDate: Date, site: string,keyword: string) {
    this.ticket$ = this.ticketService.getByDateRangeKeywordSiteHasSearch(startDate, endDate, site, keyword)
      .snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Ticket;
          const id = a.payload.doc['id'];
          return { id, ...data };
        }))
      );
  }

  getByDateRangeKeywordSite(startDate: Date, endDate: Date,site: string) {
    this.ticket$ = this.ticketService.getByDateRangeKeywordSite(startDate, endDate, site)
      .snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Ticket;
          const id = a.payload.doc['id'];
          return { id, ...data };
        }))
      );
  }

  clear() {
    this.dateRange = null
    this.search()
  }
  buildForm() {
    const model: IMyDateModel = { isRange: true, singleDate: { jsDate: new Date() }, dateRange: null };
    this.filterTicketForm = this.fb.group({
      date: [model, [Validators.required]]
    })
  }
}
