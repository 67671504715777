import { Component, OnInit } from '@angular/core';
import { SiteService } from 'src/app/services/site/site.service';
import { Site } from 'src/app/models/site.model';
import * as moment from 'moment';
import { DataService } from 'src/app/services/data/data.service';
import Swal from 'sweetalert2';
import { TicketService } from '../../services/ticket/ticket.service';
import { Ticket } from 'src/app/models/ticket.model';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  p = 1;
  Site: Site[];
  hideWhenNoStaff = false;
  noData = false;
  preLoader = true;
  searchValue = '';
  ticket$: any;
  CountStatus :any[];
  Status = [
    { value: 'Informed' },
    { value: 'More Info' },
    { value: 'In Progress' },
    { value: 'Accepted', },
    { value: 'Assigned', },
    { value: 'Resolved' },
    { value: 'Rejected' },
    { value: 'Pending' }
  ]
  CountStatusAssigned: any[];
  CountStatusDraft: any[];
  CountStatusInformed: any[];
  CountStatusMoreInfo: any[];
  CountStatusAccepted: any[];
  CountStatusResolved: any[];
  CountStatusInProgress: any[];
  CountStatusClosed: any[];

  constructor(
    private siteService: SiteService,
    public dataService: DataService,
    private ticketService: TicketService,
  ) { }

  ngOnInit() {
    this.getSitesList()
    this.dataState();
  }

  getSitesList() {
    this.siteService.getSites().snapshotChanges().subscribe(data => {
      this.Site = [];
      data.map(items => {
        const item = items.payload.doc.data();
        item['$key'] = items.payload.doc['id'];
        this.Site.push(item as Site)
        this.Site = this.Site.sort((a,b) =>  a.initials.localeCompare(b.initials))

      })
      this.getCount(this.Site);
    });

  }

  getCount(Site) {
    this.CountStatusAccepted = [];
    this.CountStatusDraft = [];
    this.CountStatusInformed = [];
    this.CountStatusMoreInfo = [];
    this.CountStatusResolved = [];
    this.CountStatusAssigned = [];
    this.CountStatusInProgress = [];
    this.CountStatusClosed = []


    for (let i = 0; Site.length > i; i++){
      this.ticketService.getCountByStatusSite('Draft', Site[i].initials).subscribe(result => {
        this.CountStatusDraft[i] = result.length;
       });
       this.ticketService.getCountByStatusSite('Informed', Site[i].initials).subscribe(result => {
        this.CountStatusInformed[i] = result.length;
       });
       this.ticketService.getCountByStatusSite('More Info', Site[i].initials).subscribe(result => {
        this.CountStatusMoreInfo[i] = result.length;
       });
       this.ticketService.getCountByStatusSite('In Progress', Site[i].initials).subscribe(result => {
        this.CountStatusInProgress[i] = result.length;
       });
       this.ticketService.getCountByStatusSite('Accepted', Site[i].initials).subscribe(result => {
        this.CountStatusAccepted[i] = result.length;
       });
        this.ticketService.getCountByStatusSite('Assigned', Site[i].initials).subscribe(result => {
         this.CountStatusAssigned[i] = result.length;
        });
        this.ticketService.getCountByStatusSite('Resolved', Site[i].initials).subscribe(result => {
          this.CountStatusResolved[i] = result.length;
         });
        this.ticketService.getCountByStatusSite('Closed', Site[i].initials).subscribe(result => {
          this.CountStatusClosed[i] = result.length;
         });
      }
  
  }

  sumTotal(index: number) {
    return Number(this.CountStatusAccepted[index]) + Number(this.CountStatusAssigned[index]) + Number(this.CountStatusClosed[index]) + Number(this.CountStatusDraft[index]) + Number(this.CountStatusInProgress[index]) + Number(this.CountStatusInformed[index]) + Number(this.CountStatusMoreInfo[index]) + Number(this.CountStatusResolved[index])

  }



  dataState() {
    this.siteService.getSites().snapshotChanges().subscribe(data => {
      this.preLoader = false;
      if (data.length <= 0) {
        this.hideWhenNoStaff = false;
        this.noData = true;
      } else {
        this.hideWhenNoStaff = true;
        this.noData = false;
      }
    });
  }

  search() {
    if (this.searchValue !== undefined && this.searchValue !== null && this.searchValue !== '') {
      this.getSitesByNameSort(this.searchValue)
      this.dataStateSearch(this.searchValue)
    } else {
      this.getSitesList()
      this.dataState()
    }
  }

  dataStateSearch(searchValue: string) {
    this.siteService.getSitesByNameSort(searchValue).snapshotChanges().subscribe(data => {
      this.preLoader = false;
      if (data.length <= 0) {
        this.hideWhenNoStaff = false;
        this.noData = true;
      } else {
        this.hideWhenNoStaff = true;
        this.noData = false;
      }
    });
  }

  getSitesByNameSort(searchValue: any) {
    this.siteService.getSitesByNameSort(searchValue).snapshotChanges().subscribe(data => {
      this.Site = [];
      data.map(items => {
        const item = items.payload.doc.data();
        item['$key'] = items.payload.doc['id'];
        this.Site.push(item as Site)
      })
    });
  }

  getMaPackage(site: Site) {

    if (site.maStartDate.singleDate) {
      const startDate = moment(site.maStartDate.singleDate.jsDate.seconds * 1000).format('L');
      const endDate = moment(site.maEndDate.singleDate.jsDate.seconds * 1000).format('L');
      return startDate + ' - ' + endDate;
    }
    return '-'

  }

  getMaPackages(inint: any) {
    return 0
  //   let count: number = 0
  //  this.ticketService.getCountByStatusSite('Assigned', inint).subscribe(result => {
  //   console.log(result.length)
  //   count = result.length
  // }); 


  }

  getCountByStatus(inint: any) {
    let c
    // for (let i = 0; this.Status.length > i; i++) {
      this.ticketService.getCountByStatusSite('Assigned', inint).subscribe(result => {
        console.log( result.length);
c = result.length
      });
      return c
    // }
  }

  setExpirationDate(site: Site) {
    let color = ''
    if (site.maStartDate.singleDate) {
      const endDate = new Date(site.maEndDate.singleDate.jsDate.seconds * 1000)
      const currentDate = new Date()
      if (endDate > currentDate) {
        color = 'currentDate'
      } else {
        color = 'expirationDate'
      }
      return color
    }
    return color = 'expirationDate'
  }

  newPath() {
    this.dataService.changeRedirectSource('site')
  }

  alertDeleteSite(site) {
    Swal.fire({
      title: 'Do you want to delete site?',
      text: site.nameEN,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2ED0B9',
      cancelButtonColor: '#9C9FA6',
      confirmButtonText: 'Yes, I do'
    }).then((result: { isConfirmed: any; }) => {
      if (result.isConfirmed) {
        this.siteService.deleteSiteById(site.$key)
      }
    })
  }

}
