import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { Observable } from 'rxjs';
import { Site } from 'src/app/models/site.model';
import { User } from 'src/app/models/user.model';
import { Event } from 'src/app/models/event.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DataService } from 'src/app/services/data/data.service';
import { EventService } from 'src/app/services/event/event.service';
import { SiteService } from 'src/app/services/site/site.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-edit-event',
  templateUrl: './edit-event.component.html',
  styleUrls: ['./edit-event.component.css']
})
export class EditEventComponent implements OnInit {

  constructor(
    public auth: AuthService,
    public siteService: SiteService,
    private fb: FormBuilder,
    private eventService: EventService,
    public router: Router,
    public userService: UserService,
    public dataService: DataService,
    public route: ActivatedRoute,
  ) { 
    this.route.params.subscribe(params => this.id = params.id)
  }
  id: string;
  users: any;
  user: User;
  site$: Observable<any>;
  user$: any;
  Status = [true, false]
  public eventForm: FormGroup;
  hideResponse = false;
  User: User;
  Site: Site[];
  myOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mm/yyyy',
  };
  redirectPath: string
  events: Event;

  private static formatDate(date: Date) {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    const year = date.getFullYear();
    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }
    return [day, month, year].join('/');
  }

  ngOnInit() {
  this.auth.user$.subscribe(user => this.user = user);
    this.dataService.currentRedirect.subscribe(redirectPath => this.redirectPath = redirectPath)
    this.User = this.auth.authState;
    this.site$ = this.siteService.getSitesListTH();
    this.buildForm();
    this.setDateDefault()
    this.getEvent()
  }

  buildForm() {
    const model: IMyDateModel = { isRange: false, singleDate: { jsDate: new Date() }, dateRange: null };
    this.eventForm = this.fb.group({
      site: [''],
      siteName: [''],
      date: [model, [Validators.required]],
      timeStart: [''],
      timeEnd: [''],
      event: ['', [
        Validators.required,
        Validators.maxLength(500)]
      ],
    });
  }

  getEvent() {
    this.eventService.getEventById(this.id).subscribe(event => {
      this.events = event
      //console.log(this.event);
      this.eventForm.patchValue({
        site: this.events.site,
        siteName: this.events.site.initials,
        date: this.events.date,
        timeStart: this.events.timeStart,
        timeEnd: this.events.timeEnd,
        event: this.events.event,
       
      })
    })
  }

  get site() {
    return this.eventForm.get('site')
  }


  get date() {
    return this.eventForm.get('date')
  }

  get timeStart() {
    return this.eventForm.get('timeStart')
  }

  get timeEnd() {
    return this.eventForm.get('timeEnd')
  }

  get event() {
    return this.eventForm.get('event')
  }

  editEvent() {
    this.eventService.updateEvent(this.eventForm.value, this.id)
  }

  setDateDefault(): void {
    const date = new Date();
    this.eventForm.patchValue({
      date: {
        dateRange: null,
        isRange: false,
        singleDate: {
          date: {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate()
          },
          formatted: EditEventComponent.formatDate(date),
          jsDate: new Date()
        }
      }
    });
  }

  alertCancelEditEvent() {
    // Swal.fire({
    //   title: 'Do you want to cancel add product.',
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#2ED0B9',
    //   cancelButtonColor: '#9C9FA6',
    //   confirmButtonText: 'Yes, I do'
    // }).then((result: { isConfirmed: any; }) => {
    //   if (result.isConfirmed) {
        this.router.navigate([`/eventsite`]);
    //   }
    // })
  }

}