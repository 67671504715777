import { Product } from '../../models/product.model';
import { MaInfo, Server, Site } from '../../models/site.model';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SiteService {
  constructor(
    private afs: AngularFirestore,
    private router: Router,
  ) { }

  getSitesList() {
    return this.afs.collection<Site>('site').valueChanges().pipe(switchMap(Site => {
      const productIds = (Site.map(Site => Site.productId));
      return combineLatest(of(Site), combineLatest(productIds.map(productId =>
        this.afs.collection<Product>('product', ref =>
          ref.where('id', '==', productId)).valueChanges().pipe(map(product => product[0]))
      )));
    }),
      map(([Site, product]) => {
        return Site.map(Site => {
          return {
            ...Site,
            product: product.find(a => a.id === Site.productId)
          };
        });
      }))
  }

  getSitesListTH() {
    return this.afs.collection<Site>('site').valueChanges().pipe(switchMap(Site => {
      const productIds = (Site.map(Site => Site.productId));
      return combineLatest(of(Site), combineLatest(productIds.map(productId =>
        this.afs.collection<Product>('product', ref =>
          ref.where('id', '==', productId)).valueChanges().pipe(map(product => product[0]))
      )));
    }),
      map(([Site, product]) => {
        return Site.map(Site => {
          return {
            ...Site,
            product: product.find(a => a.id === Site.productId)
          };
        }).sort((a,b) =>  a.nameTH.localeCompare(b.nameTH));
      })
    )
  }

  getSiteByName(site: string) {
    return this.afs.collection<Site>('site', (ref) => ref.where('initials', '==', site)).valueChanges().pipe(switchMap(Site => {
      const productIds = (Site.map(Site => Site.productId));
      return combineLatest(of(Site), combineLatest(productIds.map(productId =>
        this.afs.collection<Product>('product', ref =>
          ref.where('id', '==', productId)).valueChanges().pipe(map(product => product[0]))
      )));
    }),
      map(([Site, product]) => {
        return Site.map(Site => {
          return {
            ...Site,
            product: product.find(a => a.id === Site.productId)
          };
        });
      }))
  }

  getSites() {
    return this.afs.collection('site', (ref) => ref
      .orderBy('nameEN', 'asc')
    );
  }

  getSitesByNameSort(keyword) {
    return this.afs.collection('site', (ref) => ref
      .where('keyword', 'array-contains', keyword)
    )
  }

  getSitesByName(site: string) {
    return this.afs.collection('site', (ref) => ref.where('initials', '==', site))
  }

  getProvinces() {
    return this.afs.collection('province')
  }

  getSiteById(id: string) {
    return this.afs.doc<Site>(`site/` + id).valueChanges();
  }

  async addSite(site: Site) {
    try {
      const keyword = await this.generateKeyword(site.nameEN, site.nameTH, site.initials);
      (await this.afs.collection('site').add({
        initials: site.initials,
        nameEN: site.nameEN,
        nameTH: site.nameTH,
        productId: site.productId,
        maLevelId: site.maLevelId,
        maStartDate: site.maStartDate,
        maEndDate: site.maEndDate,
        module: site.module,
        addresses: site.addresses,
        opacLink: site.opacLink,
        yearOfInstall: site.yearOfInstall,
        keyword
      }).then((docRef) => {
        this.successNotification('site-mng', docRef.id);
      }))
    } catch (error) {
      console.log(error);
    }
  }

  async updateSite(site: Site, id: string) {
    try {
      const keyword = await this.generateKeyword(site.nameEN, site.nameTH, site.initials);
      (await this.afs.collection('site').doc(id).update({
        initials: site.initials,
        nameEN: site.nameEN,
        nameTH: site.nameTH,
        productId: site.productId,
        maLevelId: site.maLevelId,
        maStartDate: site.maStartDate,
        maEndDate: site.maEndDate,
        module: site.module,
        addresses: site.addresses,
        opacLink: site.opacLink,
        yearOfInstall: site.yearOfInstall,
        keyword
      }).then(() => {
        this.successNotification('site-mng', id)
      }))
    } catch (error) {
      console.log(error);
    }
  }

  async deleteSiteById(id: any) {
    try {
      await this.afs.collection('site').doc(id).delete();
      this.successDelete()
    } catch (err) {
      console.log(err);
    }
  }

  successNotification(path: string, data?: any | null) {
    Swal.fire({
      text: 'Your site has been saved',
      icon: 'success',
    }).then((result: any) => {
      this.router.navigate([`/${path}/${data}`]);
    });
  }

  errorNotification(path: string) {
    Swal.fire({
      icon: 'error',
      title: 'error',
      text: 'Your site hasn\'t been saved',
    }).then((result: any) => {
      this.router.navigate([`/${path}`]);
    });
  }

  successDelete() {
    Swal.fire({
      icon: 'success',
      title: 'deleted',
      text: 'Your site has been deleted',
    }).then((result: any) => {
      this.router.navigate([`site`]);
    });
  }

  errorDelete() {
    Swal.fire({
      icon: 'error',
      title: 'error',
      text: 'Your site hasn\'t  been deleted',
    }).then((result: any) => {
      this.router.navigate([`site`]);
    });
  }

  successSeverDelete(path: string, data?: any | null) {
    Swal.fire({
      icon: 'success',
      title: 'deleted',
      text: 'Your server has been deleted',
    }).then((result: any) => {
      this.router.navigate([`/${path}/${data}`]);
    });
  }

  errorServerDelete(path: string, data?: any | null) {
    Swal.fire({
      icon: 'error',
      title: 'error',
      text: 'Your server hasn\'t  been deleted',
    }).then((result: any) => {
      this.router.navigate([`/${path}/${data}`]);
    });
  }

  private async generateKeyword(siteEN: string, siteTH, initials) {
    function creatKeywords(str: string) {
      const arrName = [];
      let temp = "";
      for(var i=0;i< str.length; i++) {
        if(str[i] == " ") {
          temp = "";
        } else {
          temp = temp + str[i];
          arrName.push(temp);
        }
      }
      return arrName
    }
    const keywordTH = await creatKeywords(siteTH)
    const keywordENLowerCase = await creatKeywords(siteEN.toLowerCase())
    const keywordENUpperCase = await creatKeywords(siteEN.toUpperCase())
    const keywordInitialsLowerCase = await creatKeywords(initials.toLowerCase())
    const keywordInitialsUpperCase = await creatKeywords(initials.toUpperCase())
    return [
      '',
      ...keywordTH,
      ...keywordENLowerCase,
      ...keywordENUpperCase,
      ...keywordInitialsLowerCase,
      ...keywordInitialsUpperCase
    ]
  }

  getServer(id: string) {
    return this.afs.collection('site').doc(id).collection('server')
  }

  getMAinfo(id: string) {
    return this.afs.collection('site').doc(id).collection('mainfo', (ref) => ref
    .orderBy('maStartDate.singleDate.jsDate', 'desc'));
  }

  async addServer(id: string, server: Server) {
    try {
      (await this.afs.collection('site').doc(id).collection('server')
        .add({
          serverIpName: server.serverIpName,
          serverDescription: server.serverDescription,
          serverType: server.serverType,
          userLogin: server.userLogin
        }).then(() => {
          this.successNotification('site-mng', id)
        }))
    } catch (error) {
      console.log(error);
    }
  }

  async addMA(id: string, info: any) {
    try {
      (await this.afs.collection('site').doc(id).collection('mainfo')
        .add({
          maLevelId: info.maLevelId.maLevelId,
          productId: info.productId.productId,
          maLevelName: info.maLevelId.maLevelName,
          productName: info.productId.productName,
          maStartDate: info.maStartDate,
          maEndDate: info.maEndDate,
          module: info.module,
          MADescription: info.MADescription,
          fee: info.fee,
        }).then(() => {
          this.successNotification('site-mng', id)
        }))
    } catch (error) {
      console.log(error);
    }
  }

  async updateServer(id: string, server: Server) {
    try {
      (await this.afs.collection('site').doc(id)
        .collection('server', ref => ref
          .doc(server.id)
          .update({
            serverIpName: server.serverIpName,
            serverDescription: server.serverDescription,
            serverType: server.serverType,
            userLogin: server.userLogin
          }).then(() => {
            this.successNotification('site-mng', id)
          })
        ))
    } catch (error) {
      console.log(error);
    }
  }

  async updateMA(id: string, info: any) {
      //console.log(info)
    try {
      (await this.afs.collection('site').doc(id)
        .collection('mainfo', ref => ref
          .doc(info.id)
          .update({
            id: info.id,
            maLevelId: info.maLevelId,
            productId: info.productId,
            maLevelName: info.maLevelName,
            productName: info.productName,
            maStartDate: info.maStartDate,
            maEndDate: info.maEndDate,
            module: info.module,
            MADescription: info.MADescription,
            fee: info.fee,
          }).then(() => {
            this.successNotification('site-mng', id)
          })
        ))
    } catch (error) {
      console.log(error);
    }
  }

  async removeServer(id: any, server: Server) {
    try {
      (await this.afs.collection('site').doc(id)
        .collection('server').doc(server.id).delete()
        .then(() => {
          this.successSeverDelete('site-mng', id)
        }))
    } catch (error) {
      console.log(error);
    }
  }

  async removeMAinfo(id: any, info: MaInfo) {
    try {
      (await this.afs.collection('site').doc(id)
        .collection('mainfo').doc(info.id).delete()
        .then(() => {
          this.successSeverDelete('site-mng', id)
        }))
    } catch (error) {
      console.log(error);
    }
  }

}
