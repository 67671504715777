import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExportReportExcelService {

  constructor() { }
  public exportAsExcelFile(json: any[], excelFileName: string, reportTitle: string, reportDetail: string, dateCondition : string, headers: string[]): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([{ A: reportTitle }], { skipHeader: true });
    // const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const custfmt = '0.0';

    XLSX.utils.sheet_add_json(worksheet, [{ A: reportDetail }], { skipHeader: true, origin: 'A2' });
    XLSX.utils.sheet_add_json(worksheet, [{ A: dateCondition }], { skipHeader: true, origin: 'A3' });
    XLSX.utils.sheet_add_json(worksheet, json, { origin: 'A7' });
    XLSX.utils.sheet_add_json(worksheet, headers, { skipHeader: true, origin: 'A7' });
    //  XLSX.utils.cell_set_number_format(worksheet['C'], custfmt);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };

    // const money_format = workbook.add_format({num_format: '$#,##0'});
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

public mapColumnExport(displayedColumns:any[],temphead: any[]): void{

  





}












}
