import { ProductService } from './../../services/product/product.service';
import { Product } from './../../models/product.model';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

declare var $: any;
@Component({
  selector: 'module',
  templateUrl: './module.component.html',
  styleUrls: ['./module.component.css']
})

export class ModuleComponent implements OnInit {
  id: string;
  p = 1;
  Mod
  Product: Product[];
  hideWhenNoStaff = false;
  noData = false;
  preLoader = true;
  searchValue = '';
  moduleList: any[];
  moduleSearchlist: any[];
  MoForm: FormGroup;
  selectEditname: string;
  Module: any[]

  constructor(
    private productService: ProductService,
    public router: Router,
    private product: ProductService,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.buildForm();
    this.dataState();
    this.getProduct()
    this.getModule();
  }

  buildForm() {
    this.MoForm = this.fb.group({
      moduleName: ['',Validators.required],
    })
  }

  editMoinfo(name) {
    this.selectEditname = name;
    $('#exampleModal').modal('show');
      this.MoForm.patchValue({
        moduleName: name,
      })
  }

  get moduleName() {
    return this.MoForm.get('moduleName')
  }

  getModule() {
    this.product.getModule().snapshotChanges().subscribe(data => {
      this.Module = [];
      data.map(items => {
        const item = items.payload.doc.data();
        item['$uid'] = items.payload.doc['id'];
        this.id = items.payload.doc['id']
        this.Module.push(item)
        this.moduleList = (this.Module[0].module).sort((a,b) =>  a.localeCompare(b));
      })
    })
  } 
  getProduct() {
    this.productService.getProductList().snapshotChanges().subscribe(data => {
      this.Product = [];
      data.map(items => {
        const item = items.payload.doc.data();
        item['$uid'] = items.payload.doc['id'];
        this.Product.push(item as Product)
      })
    });
  }

  dataState() {
    this.productService.getModule().snapshotChanges().subscribe(data => {
      this.preLoader = false;
      if (data.length <= 0) {
        this.hideWhenNoStaff = false;
        this.noData = true;
      } else {
        this.hideWhenNoStaff = true;
        this.noData = false;
      }
    });
  }

  search() {
    if (this.searchValue !== undefined && this.searchValue !== null && this.searchValue !== '') {
      this.getProductByNameSort(this.searchValue)
    } else {
      this.dataState();
      this.getModule()
    }
  }

  getProductByNameSort(searchValue: any) {
    let moduleSearchlist = this.moduleList;
    moduleSearchlist = moduleSearchlist.filter(el => el.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1)
    if (moduleSearchlist.length <= 0) {
      this.hideWhenNoStaff = false;
      this.noData = true;
    } else {
      this.hideWhenNoStaff = true;
      this.noData = false;
      this.moduleList = moduleSearchlist.sort((a,b) =>  a.localeCompare(b));
    }
  }

  getActive(product: Product) {
    let active: string
    if (product.active) {
      active = 'True'
    } else {
      active = 'False'
    }
    return active
  }

  alertDeleteModule(name: any) {
    Swal.fire({
      title: 'Do you want to delete module.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2ED0B9',
      cancelButtonColor: '#9C9FA6',
      confirmButtonText: 'Yes, I do'
    }).then((result: { isConfirmed: any; }) => {
      if (result.isConfirmed) {
        let c = this.moduleList.filter(e => e !== name);
        this.moduleList = c;
        this.product.updateModule(this.id,this.moduleList)
      }
    })
  }

  alertCancelAddInfo() {
    Swal.fire({
      title: 'Do you want to cancel add Module.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2ED0B9',
      cancelButtonColor: '#9C9FA6',
      confirmButtonText: 'Yes, I do'
    }).then((result: { isConfirmed: any; }) => {
      if (result.isConfirmed) {
        this.MoForm.reset();
        $('#addModule').modal('hide');
      }
    })
  }


  alertCancelEditInfo() {
    Swal.fire({
      title: 'Do you want to cancel edit Module.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2ED0B9',
      cancelButtonColor: '#9C9FA6',
      confirmButtonText: 'Yes, I do'
    }).then((result: { isConfirmed: any; }) => {
      if (result.isConfirmed) {
        this.MoForm.reset();
        $('#editModule').modal('hide');
      }
    })
  }

  async addMo() {
    if (this.MoForm.controls.moduleName.value != '' || this.MoForm.controls.moduleName.value != null) {
      this.moduleList.push(this.MoForm.controls.moduleName.value);
      this.product.updateModule(this.id,this.moduleList)
      $('#addModule').modal('hide');
      this.MoForm.reset();
    } else {
      $('#addModule').modal('hide');
    }
    this.MoForm.reset();
  }

  async editMo() {
    if (this.MoForm.controls.moduleName.value != '' || this.MoForm.controls.moduleName.value != null) {
      let c = this.moduleList.map((e) => e =  e.replace(this.selectEditname, this.MoForm.controls.moduleName.value ));
      this.moduleList = c;
      this.product.updateModule(this.id,this.moduleList)
      $('#editModule').modal('hide');
      this.MoForm.reset();
    } else {
      $('#editModule').modal('hide');
    }
    this.MoForm.reset();
  }

}