import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { IAngularMyDpOptions, IMyDate, IMyDateModel } from 'angular-mydatepicker';
import { Product } from 'src/app/models/product.model';
import { MaInfo, Server } from 'src/app/models/site.model';
import { SiteService } from 'src/app/services/site/site.service';
import { ProductService } from 'src/app/services/product/product.service';
import Swal from 'sweetalert2';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MaLevel } from './../../models/site.model';
import * as moment from 'moment';
import { Observable } from 'rxjs';

declare var $: any;
@Component({
  selector: 'app-site-maintenance-info',
  templateUrl: './site-maintenance-info.component.html',
  styleUrls: ['./site-maintenance-info.component.css']
})
export class SiteMaintenanceInfoComponent implements OnInit {
  @Input() id: string
  MAForm: FormGroup;
  isAdd = true;
  isEdit = false;
  Server: Server[];
  MAinfo: MaInfo[];
  Product: Product[];
  MaLevel: MaLevel[];
  product$: Observable<Product>;
  maLevel$: Observable<MaLevel>;
  Module: any[]
  moduleList: any[];
  productThis: any;
  maLevelThis: any;
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    noDataAvailablePlaceholderText: 'Please choose module',
    allowSearchFilter: true,
    enableCheckAll: true,
  };
  detailConfig: AngularEditorConfig = {
    editable: false,
    spellcheck: false,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: false,
    showToolbar: false,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ],
  };
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ],
  };
  ServerType = ['Database Server', 'Remote Server', 'OPAC Server', 'Solr Server', 'Imaegs Server', 'VPN Server', 'Loadbalance Server']
  isShow = false;
  serverDetails: any;
  // myOptions2: { dateRange: boolean; dateFormat: string; disableUntil: any; };
  myOptions1: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mm/yyyy',
  };
  myOptions2: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mm/yyyy',
  };
  constructor(
    private fb: FormBuilder,
    private siteService: SiteService,
    private product: ProductService,
  ) { }
  ngOnInit() {
    this.buildForm()
    this.getProduct()
    this.getModule()
    this.getMaLevel()
    this.getMAinfo()
  }
  
  getMAinfo() {
    this.siteService.getMAinfo(this.id).snapshotChanges().subscribe(data => {
      this.MAinfo = []
      data.map(items => {
        const item = items.payload.doc.data();
        item['id'] = items.payload.doc['id'];
        item['product'] = this.getProductById(items.payload.doc['productId'])
        item['maLevel'] = this.getMaLevelById(items.payload.doc['maLevelId'])
        this.MAinfo.push(item as MaInfo);
      })
    })
  }

  // item['productName'] = this.getProductById(items.payload.doc['productId'])
  // item['maLevelName'] = this.getMaLevelById(items.payload.doc['maLevelId'])
  getProductById(id: string) {
    return  this.product.getProductById(id)
  }

  getMaLevelById(id: string) {
    return this.product.getMaLevelById(id)
  }

  getProductForm(id: string) {
    var test
    this.productThis = this.product.getProductById(id);
    test = this.productThis as Product
  }

  getMaLevelForm(id: string) {
    return this.maLevelThis = this.product.getMaLevelById(id)
  }

  setExpirationDate(info: MaInfo) {
    let color: string
    if (info.maStartDate == "") {
      color = 'expirationDate'
      return color 
     } 
     else {
      const endDate = new Date(info.maEndDate.singleDate.jsDate.seconds * 1000)
      const currentDate = new Date()
      if (endDate > currentDate) {
        color = 'currentDate'
      } else {
        color = 'expirationDate'
      }
      return color
     }
  }

  getMaPackage(info: MaInfo) {
    if (info.maStartDate == "") {
      return "-" 
     } 
     else {
    const maStartDate = moment(info.maStartDate.singleDate.jsDate.seconds * 1000).format('L');
    const maEndDate = moment(info.maEndDate.singleDate.jsDate.seconds * 1000).format('L');
    return maStartDate + ' - ' + maEndDate;
     }
  }

  buildForm() {
    this.MAForm = this.fb.group({
      id: [''],
      maLevelName: [''],
      maLevelId: [''],
      maStartDate: [''],
      maEndDate: [''],
      productName: [''],
      productId: [''],
      MADescription: [''],
      module: [''],
      fee: [''],
    })
  }




  get MADescription() {
    return this.MAForm.get('serverDescription')
  }




  async addMA() {
    if (this.MAForm.controls.id.value) {
      this.siteService.updateMA(this.id, this.MAForm.value);
      this.getMAinfo();
      this.MAForm.reset();
    } else {
      this.siteService.addMA(this.id, this.MAForm.value);
      this.getMAinfo();
      this.MAForm.reset();
      this.ngOnInit()
    }
    $('#exampleModal').modal('hide');
    this.getMAinfo();
    this.MAForm.reset();
    this.isAdd = true;
    this.isEdit = false;
  }

  showAdd(value: boolean) {
    this.MAForm.reset();
    this.isAdd = value
    this.isEdit = !value
    this.isShow = false;
  }

  alertCancelAddInfo() {
    if (this.isAdd){
      Swal.fire({
        title: 'Do you want to cancel add Maintenance Information.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2ED0B9',
        cancelButtonColor: '#9C9FA6',
        confirmButtonText: 'Yes, I do'
      }).then((result: { isConfirmed: any; }) => {
        if (result.isConfirmed) {
          this.MAForm.reset();
          this.isAdd = true;
          this.isEdit = false;
          this.isShow = false;
          $('#exampleModal').modal('hide');
        }
      })
    } else {
      Swal.fire({
        title: 'Do you want to cancel edit Maintenance Information.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2ED0B9',
        cancelButtonColor: '#9C9FA6',
        confirmButtonText: 'Yes, I do'
      }).then((result: { isConfirmed: any; }) => {
        if (result.isConfirmed) {
          this.MAForm.reset();
          this.isAdd = false;
          this.isEdit = true;
          this.isShow = false;
          $('#exampleModal').modal('hide');
        }
      })
    }
 
  }

  editServer(server: Server) {
    this.isShow = false
    this.isAdd = false
    this.isEdit = true
    if (this.isEdit) {
      this.MAForm.patchValue({
        id: server.id,
        serverIpName: server.serverIpName,
        serverDescription: server.serverDescription,
        serverType: server.serverType,
      })
    }
  }

  AddMAinfo() {
    this.isShow = false
    this.isAdd = true
    this.isEdit = false
    $('#exampleModal').modal('show');
  }

  editMAinfo(MaInfo: MaInfo) {
    this.isShow = false
    this.isAdd = false
    this.isEdit = true
    $('#exampleModal').modal('show');
    if (this.isEdit) {
      this.MAForm.patchValue({
        id: MaInfo.id,
        maLevelName: MaInfo.maLevelName,
        maLevelId: MaInfo.maLevelId,
        maStartDate: MaInfo.maStartDate,
        maEndDate: MaInfo.maEndDate,
        productName: MaInfo.productName,
        productId: MaInfo.productId,
        MADescription: MaInfo.MADescription,
        module: MaInfo.module,
        fee: MaInfo.fee,
      })
    }
  }

  setMaLevelId(e) {
    let getMaLevelById = this.getMaLevelById(this.MAForm.controls.maLevelId.value);
      getMaLevelById.subscribe(data => { 
         this.MAForm.controls.maLevelName.setValue(data.name)   
      })
  }

  setProductById(e) {
      let getProductById = this.getProductById(this.MAForm.controls.productId.value);
        getProductById.subscribe(data => {
        this.MAForm.controls.productName.setValue(data.name)
      })  
  }

  alertDeleteMAinfo(maInfo: MaInfo) {
    Swal.fire({
      title: 'Do you want to delete Maintenance Information.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2ED0B9',
      cancelButtonColor: '#9C9FA6',
      confirmButtonText: 'Yes, I do'
    }).then((result: { isConfirmed: any; }) => {
      if (result.isConfirmed) {
        this.siteService.removeMAinfo(this.id, maInfo)
      }
    })
  }

  showServer(serv: any) {
    this.MAForm.reset();
    this.isAdd = false;
    this.isEdit = false;
    this.isShow = true;
    this.serverDetails = serv
  }

  minDate(event: IMyDateModel): IMyDate {
    const date = event.singleDate.jsDate
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    return { year, month, day }
  }

  maxDate(event: IMyDateModel): IMyDate {
    const date = event.singleDate.jsDate
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    return { year, month, day }
  }

  setMinDate($event: IMyDateModel) {
    this.myOptions2 = {
      dateRange: false,
      dateFormat: 'dd/mm/yyyy',
      disableUntil: this.minDate($event),
    };
  }

  setMaxDate($event: IMyDateModel) {
    this.myOptions1 = {
      dateRange: false,
      dateFormat: 'dd/mm/yyyy',
      disableSince: this.maxDate($event),
    };
  }
  get maStartDate() {
    return this.MAForm.get('maStartDate');
  }

  get maEndDate() {
    return this.MAForm.get('maEndDate');
  }


  get productId() {
    return this.MAForm.get('productId');
  }

  // get productName() {
  //   return this.MAForm.get('productId').value.productName;
  // }


  get maLevelId() {
    return this.MAForm.get('maLevelId');
  }

  get module() {
    return this.MAForm.get('module');
  }

  get fee() {
    return this.MAForm.get('fee');
  }

  getProduct() {
    this.product.getProductList().snapshotChanges().subscribe(data => {
      this.Product = [];
      data.map(items => {
        const item = items.payload.doc.data();
        item['$uid'] = items.payload.doc['id'];
        this.Product.push(item as Product)
      })
    });
  }

  getModule() {
    this.product.getModule().snapshotChanges().subscribe(data => {
      this.Module = [];
      data.map(items => {
        const item = items.payload.doc.data();
        item['$uid'] = items.payload.doc['id'];
        this.Module.push(item)
        this.moduleList = (this.Module[0].module).sort((a,b) =>  a.localeCompare(b))
      })
      
    })
  }


  getMaLevel() {
    this.product.getMaLevel().snapshotChanges().subscribe(data => {
      this.MaLevel = []
      data.map(items => {
        const item = items.payload.doc.data();
        item['$uid'] = items.payload.doc['id'];
        this.MaLevel.push(item as MaLevel)
      })
    })
  }




}
